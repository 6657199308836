<template>
  <div id="app" @click="bodyClick">
    <div class="my-container">
        <ckt-cop-new-header
          v-show="!isCktHeaderHidden"
          :route="$route"
          :route-path="currentRoutePath" 
          :team-id="teamId"
          :is-index="isFullscreenMode"
          :user-info-data="JSON.stringify(userInfo)"
          :is-login="isLogin"
          :team-list="JSON.stringify(teams)"
          :team-info-data="JSON.stringify(teamInfo)"
          :scroll-top="scrollTop"
          :normal-notice-data="JSON.stringify(normalNotice)"
          :vip-trail-expire-date="vipTrailExpireDate"
          :is-work-weixin-user="isWorkWeixinUser"
          :notice-list="mountingNoticeList"
          :is-provip="isProVip"
          :is-special-wx-team-tag="isWorkWeixinUser"
          :gift-number="giftNumber"
          :message-count-info-data="JSON.stringify(messageCountInfo)"
          :logo-data-info="JSON.stringify(getLogoData)"
          :is-show-search-bar= "isShowSearchBar"
          :can-create-free-team="canCreateFreeTeam"
          :tracker-base-params-data="JSON.stringify(trackerBaseParams)"
          class="header"
          @logout="logout"
          @set-logreg-show="(v)=>{setLogregShowInHeader(isIncludeDeatail(v))}"
          @set-team-id="(v)=>{setTeamId(isIncludeDeatail(v))}"
          @getUserInfo="getUserInfo"
          @getVipDetail="getVipDetail"
          @message-show="messageCenterPopShow = true"
          @set-search-keywords="(v)=>{setSearchKeywords(isIncludeDeatail(v))}"
          @rights-buy-pop="(e)=>{showrightsAndBuyPop(isIncludeDeatail(e), 'buy')}"
          @trial-expired-tips-change="(v)=>{trialExpiredTipsChange(isIncludeDeatail(v))}"
          @set-connect-wx-success="connectWxSuccess = true"
          @the-entrance-status="(ev) => ChangeTheEntrance(isIncludeDeatail(ev))"
          @giftShow="(v)=>{giftShow(isIncludeDeatail(v))}"
          @getCanCreateFreeTeam="getCanCreateFreeTeam"
          @setRechargeShow="(v)=>{setRechargeShow(isIncludeDeatail(v))}"
          @getwwAuthUser="(v) => {wwAuthUser = isIncludeDeatail(v)}"
          @setHeaderActivityShow="
            v => {
              setHeaderActivityShow(isIncludeDeatail(v));
            }
          "
          @setTeamEntry="(v) => {teamEntryState = isIncludeDeatail(v)}"
          @buyTietie="buyTietie"
          >
        </ckt-cop-new-header>
        <div :class="selfStyle" v-if="isShowSearchBar && !isCktHeaderHidden">
          <SearchBar
            :isLogin="isLogin"
            :userInfo="userInfo"
            @setIsEXpand="
              (e) => {
                searchBarIsExpand = e;
              }
            "
            @set-search-keywords="setSearchKeywords"
          />
        </div>
      </div>

    <!-- 消息通知 -->
    <Notice
      v-if="noticeShow"
      :notice="notice"
      @closeNotice="noticeShow = false"
      @promptShow="promptShow"
    />

    <div
      id="main-content"
      ref="mainContent"
      :class="{ index: isFullscreenMode && scrollTop === 0 }"
      :style="{
        height: contentHeight,
      }"
      @scroll="handleScroll"
    >
      <transition appear name="slide" mode="out-in">
        <router-view
          :isLogin="isLogin"
          :userInfo="userInfo"
          :isResize="isResize"
          :teamId="teamId"
          :teamInfo="teamInfo"
          :teams="teams"
          :style="router_view_style"
          :orderNo="orderNo"
          :noticeShow="noticeShow"
          :isYouZanStates="isYouzanCustomStates"
          :scrollTop="scrollTop"
          :adPositionInfo="adPositionInfo"
          :vipTrailExpireDate="vipTrailExpireDate"
          :isFillAuthorization="isFillAuthorization"
          :isCloseAuthorization="isCloseAuthorization"
          :teamMemberCount="teamMemberCount"
          :isProVip="isProVip"
          :wwAuthUser="wwAuthUser"
          @setUserInfo="setUser"
          @logout="logout"
          @getUserInfo="getUserInfo"
          @setTeamId="setTeamId"
          @promptShow="promptShow"
          @setSelectJobsShow="selectJobShow = true"
          @rights-buy-pop="showrightsAndBuyPop"
          @open-svip="openSvip"
          @vip-team-invite-pop="handleInviteTeam"
          @goToTop="goToTop"
          @getTeamInfo="getTeamInfo"
          @setAuthorizationPopShow="setAuthorizationPopShow"
          @setscreenMarketingForm="setscreenMarketingForm"
          @logreg-success="handlelogregLoginSucceed"
          @handleChangeNavScroll="handleChangeNavScroll"
          @setLogregShow="setLogregShow"
        ></router-view>
      </transition>
    </div>
    <!--充值弹窗-->
    <RechargePop
      @getUserInfo="getUserInfo"
      @promptShow="promptShow"
      @closeRecharge="setRechargeShow(false)"
      @setLogregShow="setLogregShow"
      @closeShow="rechargeCloseShow = false"
    />

    <!-- 模板消息弹框 -->
    <div
      v-if="
        isLogin && templateMessageShow && templateMessageInfo.templateNotice
      "
      class="template-message"
      @click="handleClickTemplateMessage(templateMessageInfo, true)"
    >
      <img
        src="./base/template_message_close_icon.svg"
        alt="关闭"
        title="关闭"
        @click.stop="handleClickTemplateMessage(templateMessageInfo)"
      />
      <header
        v-if="templateMessageInfo.templateNotice.mainTitle"
        v-html="templateMessageInfo.templateNotice.mainTitle"
      ></header>
      <main>
        <p
          v-if="templateMessageInfo.templateNotice.subTitle"
          v-html="templateMessageInfo.templateNotice.subTitle"
          class="template-message__subtile"
        ></p>
        <div
          v-if="templateMessageInfo.templateNotice.content"
          v-html="
            decodeURIComponent(templateMessageInfo.templateNotice.content)
          "
          class="template-message__content"
        ></div>
        <div
          v-if="templateMessageInfo.templateNotice.button"
          class="template-message__button"
          @click="buriedPoint"
        >
          <a :href="templateMessageInfo.templateNotice.button.url">
            {{ templateMessageInfo.templateNotice.button.text }}
          </a>
        </div>
      </main>
    </div>

    <!-- 大弹框 -->
    <modal
      v-if="bigNoticeShow && !isWxWorkClient"
      :modalShow="bigNoticeShow && !isWxWorkClient"
      type="modal1"
      @modalClose="handleCloseBigNoticeModal(bigNoticeId, true)"
    >
      <a
        class="big-notice"
        :href="bigNoticeHref"
        :style="{ 'background-image': backgroundImage }"
        target="_blank"
        @click="handleCloseBigNoticeModal(bigNoticeId)"
      ></a>
    </modal>

    <!-- 消息通知右侧弹窗 -->
    <MessageCenterPop
      :isShow="messageCenterPopShow"
      :isProVip="isProVip"
      :isLogin="isLogin"
      :messageCountInfo="messageCountInfo"
      @setReadSuccess="
        () => {
          getUserInfo();
        }
      "
      @getNoticeCount="getUserNoticeCount"
      @close="messageCenterPopShow = false"
    />

    <!-- 登录 -->
    <LtModal v-model="logregModalShow" @on-cancel="handleCloseSignModal">
      <Sign
        :config="config"
        :userInfo="userInfo"
        :isLogin="isLogin"
        :type="logregShow"
        :route="$route"
        :trackerBaseParams="trackerBaseParams"
        @setUserInfo="setUserInfo"
        @receiveVip="receiveVip"
        @getUserInfo="getUserInfo"
        @set-logreg-show="setLogregShow"
        @success="handleLoginSucceed"
      />
    </LtModal>
    <!-- 领尝鲜会员弹窗 -->
    <LtModal v-model="sendVipModalShow" @on-cancel="handleCloseSendVipModal">
      <sendVip @close="handleCloseSendVipModal" />
    </LtModal>
    <!-- 领取尝鲜会员成功弹窗 -->
    <LtModal
      v-model="receivedVipModalShow"
      @on-cancel="handleCloseReceivedVipModal"
    >
      <receivedVip @close="handleCloseReceivedVipModal" />
    </LtModal>
    <!-- 老用户不可领取尝鲜会员弹窗 -->
    <LtModal
      v-model="noClaimVipModalShow"
      @on-cancel="handleCloseNoClaimVipModal"
    >
      <noClaimVip @close="handleCloseNoClaimVipModal" />
    </LtModal>

    <LtModal class="invite-member-modal" v-model="teamInvitePopShow">
      <InvitePop
        :oCtrl="false"
        :teamId="teamId"
        :teamInfo="teamInfo"
        @closeModal="handleCloseVipTeamInvite"
        @getTeamInfo="getTeamInfo"
        @rights-buy-pop="
          (e) => {
            showrightsAndBuyPop(e, 'buy');
          }
        "
      />
    </LtModal>

    <!-- 团队邀请弹窗 -->
    <!-- <inviteTeam
      v-if="teamInvitePopShow"
      :visible="teamInvitePopShow"
      :userInfo="userInfo"
      :teamInfo="teamInfo"
      @on-cancel="handleCloseVipTeamInvite"
      @getTeamInfo="getTeamInfo"
    /> -->

    <!-- 选择行业职业 -->
    <modal
      v-if="selectJobShow"
      class="select-job-modal"
      type="modal1"
      :modalShow="selectJobShow"
      @modalClose="selectJobShow = false"
    >
      <selectJob
        @getUserInfo="getUserInfo"
        @promptShow="promptShow"
        @closeModal="() => (selectJobShow = false)"
      />
    </modal>

    <!-- 浏览器兼容性提示窗 -->
    <modal
      v-if="
        this.$route.path !== '/terms/introduce' &&
        browserError &&
        !isWxWorkClient
      "
      :modalShow="
        this.$route.path !== '/terms/introduce' &&
        browserError &&
        !isWxWorkClient
      "
      :disableBlankClick="true"
      type="modal1"
      @modalClose="closeBrowserError"
    >
      <Compatible />
    </modal>

    <!-- 会员权益介绍与购买会员弹窗 -->
    <ckt-cop-vip-pop
      v-if="rightsAndBuyPopShow"
      :vip-pop-visible="rightsAndBuyPopShow"
      :user-info-str="JSON.stringify(userInfo)"
      :team-info-str="JSON.stringify(teamInfo)"
      :level="vippopParams.level"
      :location-flag="vippopParams.locationFlag"
      :source-str="JSON.stringify(vippopParams.source)"
      :active-feature-index="vippopParams.activeFeatureIndex"
      :package-level="vippopParams.packageLevel"
      :package-id="vippopParams.packageId"
      :show-buy-pop="showBuyPop"
      :tariff-package-parasm-str="JSON.stringify(tariffPackageParasm)"
      :standard-or-enterprise="vippopParams.standardOrEnterprise"
      @login="setLogregShow(1)"
      @getUserInfo="getUserInfo"
      @contactService="contactService('web_会员弹框_联系客服')"

    />

    <!-- 授权书弹窗 -->
    <AuthorizationPop
      v-if="authorizationPopShow"
      class="authorization-modal"
      :isPersonalVip="isPersonalVip"
      :authorizationPopShow="authorizationPopShow"
      :cantFillAuthorization="cantFillAuthorization"
      @close="authorizationPopShow = false"
      @cancelPop="cancelAuthorizationPop"
      @success="fillAuthorizationSuccess"
    />

    <!-- 企业微信团队到期弹窗 -->
    <wxWorkPop
      v-if="wxWorkLimit || wxWorkExpire"
      :wxWorkPopShow="wxWorkLimit || wxWorkExpire"
      :type="wxWorkLimit ? 'limit' : 'expire'"
      @close="closeWxWorkPop"
    />

    <!-- 企业微信购买人数超限弹窗 -->
    <workWeixinBuyMemberLimitPop
      v-if="wxWorkBuyMemberLimit"
      :wxWorkExpirePopShow="wxWorkBuyMemberLimit"
    />

    <!-- 会员弹窗升级 -->
    <upgradeVipTip :userInfo="userInfo" @goInvite="handleInviteTeam" />

    <!-- 绑定手机号弹窗 -->
    <!-- <BindPhone
      :userInfo="userInfo"
    /> -->
    <ScreenMarkeyFormModal
      :visible="showScreenMarkeyForm"
      @setscreenMarketingForm="setscreenMarketingForm"
    ></ScreenMarkeyFormModal>
    <!-- 手动创建免费团队 -->
    <CreateTeamModal
      ref="CreateTeamModal"
      :teamInfo="teamInfo"
      :teamId="teamId"
      @getUserInfo="getUserInfo"
      @getCanCreateFreeTeam="getCanCreateFreeTeam"
      @rights-buy-pop="
        (e) => {
          showrightsAndBuyPop(e, 'buy');
        }
      "
    >
    </CreateTeamModal>
    <giftPack
      ref="giftPack"
      :giftPackShow="giftPackShow"
      :config="config"
      :userInfo="userInfo"
      :isLogin="isLogin"
      :type="logregShow"
      :route="$route"
      :trackerBaseParams="trackerBaseParams"
      @setUserInfo="setUserInfo"
      @receiveVip="receiveVip"
      @getUserInfo="getUserInfo"
      @set-logreg-show="setLogregShow"
      @success="handleLoginSucceed"
      @giftShow="giftShow"
      @setGiftNumber="setGiftNumber"
    />
    <riskLoginPwd
      :showRiskLogin="showRiskLogin"
      :riskLoginLimitInfo="riskLoginLimitInfo"
      @closeRisk="closeRisk"
    />
    <div id="udeskContactService"></div>

    <proVipModal :proVipVisible='proVipVisible' @close='proVipVisible = false' :vipContactSalesFrom="vipContactSalesFrom" />

    <CapacityTipModal
      :teamInfo="teamInfo"
      :isLogin="isLogin"
      @rights-buy-pop="showrightsAndBuyPop"
      @open-svip="openSvip"
    />
    <!-- 吸底tabar -->
    <transition name="fade">
      <LoginLeadBar
        v-show="getShowPage"
        @gotoLogin="gotoLogin"
        :isIncludeSideBar="includePage"
      />
    </transition>
    <ckt-cop-product-recharge
      v-if="tietieRechargeVisible"
      :channel="1000"
      :tietie-pop-visible="tietieRechargeVisible"
      :user-info="JSON.stringify(userInfo)"
      :team-info="JSON.stringify(teamInfo)"
      :origin="JSON.stringify(origin)"
      @closebuymodal="tietieRechargeVisible = false"
      @paysuccess="paySuccessHandler"
      @ocs="openCustomerServiceHandler"
    />
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';
  import CktHeader from './layouts/chuangkit-components-header/header.vue';
  import SearchBar from 'components/searchBar/searchBar.vue';
  import Notice from 'components/notice/notice.vue';
  import RechargePop from 'components/rechargePop/rechargePop.vue';
  import MessageCenterPop from 'components/messageCenterPop/messageCenterPop.vue';
  import Sign from 'components/chuangkit-components-logreg/signComp.vue';
  import selectJob from './components/selectJob/selectJob.vue';
  import sendVip from 'components/sendVip/sendVip.vue';
  import receivedVip from 'components/sendVip/receivedVip.vue';
  import noClaimVip from 'components/sendVip/noClaimVip.vue';
  import Compatible from './base/checkBrowser/compatible.vue';
  import AuthorizationPop from 'components/authorizationPop/authorizationPop.vue';
  import wxWorkPop from 'components/wxWorkPop/wxWorkPop.vue';
  import upgradeVipTip from 'components/chuangkit-components-logreg/upgradeVipTip/upgradeVipTip.vue';
  import CreateTeamModal from './layouts/chuangkit-components-header/comp/createTeam/createTeamModal.vue';
  import workWeixinBuyMemberLimitPop from 'components/chuangkit-components-logreg/loginAfterComp/workWeixinBuyMemberLimitPop/workWeixinBuyMemberLimitPop.vue';
  import modal from 'components/modal/modal.vue';
  // import BindPhone from "components/chuangkit-components-logreg/loginAfterComp/bindPhone.vue";
  import InvitePop from './layouts/chuangkit-components-header/comp/createTeam/components/invitePop.vue';
  // import inviteTeam from "components/chuangkit-components-logreg/inviteTeam/inviteTeam.vue";
  import checkBrowser from './base/checkBrowser/checkBrowser.js';
  import dayjs from 'dayjs';
  import BroadcastChannel from 'broadcast-channel';
  import ScreenMarkeyFormModal from 'components/screenMarketing/FormModal.vue';
  import giftPack from 'components/chuangkit-components-logreg/giftPack/index.vue';
  import riskLoginPwd from 'components/chuangkit-components-logreg/riskLogin/index.vue';
  import CryptoJS from 'crypto-js';
  import tracker from '@/utils/tracker';
  import { sSOLogout } from '@/api';
  import { sleep } from '@/pages/intelligentFilm/comp/common';
  import proVipModal from 'components/pro-vip-modal/index.vue';
  import CapacityTipModal from "components/capacity-tip-modal/index.vue";
  import LoginLeadBar from "components/loginLeadBar/loginLeadBar.vue";


  const channel = new BroadcastChannel('ckt');

  const userTypeMap = {
    0: '免费',
    1: '企业VIP-基础版',
    2: '协作会员',
    3: '企业VIP-高级版',
    4: '个人版',
    5: '企业VIP-标准团队版',
    7: '企业VIP-加强版',
    8: '企业VIP-基础团队版',
    9: '企业VIP-全用途版',
  };
  // 首屏大图页面
  const fullscreenModeRoute = (query) => [
    { path: '/' },
    { path: '/index' },
    { path: '/newcustomera' },
    { path: '/invatationactive' },
    { path: '/screen-marketing' },
    { path: '/price/recommend_landing' },
    { path: '/terms/introduce' },
    {
      path: '/enterprise',
      notfull: query.default,
    },
  ];
  // 不展示header
  const hiddenHeaderRoute = (query) => [
    { path: '/activecenter/share' },
    { path: '/promotion' },
    { path: '/apides' },
    { path: '/terms/introduce' },
    {
      path: '/picture',
      show: !query.enterprise,
    },
    {
      path: '/intelligentfilm',
      show: !query.enterprise,
    },
    {
      path: '/intelligentfilm/design',
    },
  ];

  async function getBodyHeight() {
    const height = document.body.clientHeight;
    if (height) return height;
    await sleep(300);
    return getBodyHeight();
  }

  export default {
    name: 'app',
    components: {
      CapacityTipModal,
      CktHeader,
      SearchBar,
      Notice,
      MessageCenterPop,
      RechargePop,
      Sign,
      sendVip,
      receivedVip,
      noClaimVip,
      selectJob,
      Compatible,
      LoginLeadBar,
      AuthorizationPop,
      modal,
      InvitePop,
      // BindPhone,
      workWeixinBuyMemberLimitPop,
      ScreenMarkeyFormModal,
      giftPack,
      upgradeVipTip,
      wxWorkPop,
      riskLoginPwd,
      CreateTeamModal,
      proVipModal,
    },
    provide() {
      return {
        app: this,
        changeScrollTop: (value) => {
          this.changeScrollTop(value);
        },
      };
    },
    data() {
      return {
        config: {
          APIDOMAINV2,
          APIDOMAIN,
        },
        browserError: false,
        sysTime: 0, // 系统时间
        isFullscreenMode: false, // 该状态下位于页面顶部时 header 透明，滚动页面后恢复
        modalType: 'modal3',
        messageCenterPopShow: false,
        bigNoticeShow: false,
        contentHeight: '100vh',
        // 检测window resize
        isResize: false,
        noticeShow: false, // 是否显示浮层通知
        notice: {}, // 浮层通知内容
        bigNoticeHref: '',
        backgroundImage: '',
        orderNo: '',
        isCktHeaderHidden: false,
        router_view_style: {},
        useTdkFromVuex: false, // 从 vuex 获取 TDK flag
        showLoginEntry: true, //显示右侧头像和登录注册
        // 控制resize触发频率
        resizeThottleTimer: null,
        normalNotice: {
          subject: '',
          content: '',
          noticeId: 0,
        },
        tdkCallBackQueue: [],
        selectJobShow: false,
        isAdmin: false,
        templateMessageInfo: {},
        templateMessageShow: false,
        rechargeCloseShow: true, //  充值弹窗关闭按钮显示
        searchBarIsExpand: false, //搜索框是否是展开状态
        isYouzanCustomStates: false,
        teamInfo: {},
        scrollTop: 0,
        adPositionInfo: {}, // 广告位信息
        // 支付弹窗
        rightsAndBuyPopShow: 0, //
        teamCreatePopShow: false,
        teamInvitePopShow: false,
        vippopParams: {
          level: 4,
          duration: 0,
          locationFlag: 55,
          downloadCount: -1,
          activeFeatureIndex: 9,
        },
        vipTrailExpireDate: 0,
        showBuyPop: true,
        pageWrapTop: '',
        // 授权书弹窗
        authorizationPopShow: false, //  显示授权弹窗
        isPersonalVip: false, // 购买的套餐为个人VIP
        cantFillAuthorization: false, // 不需要填写授权书
        isFillAuthorization: false, // 已填写授权书
        isCloseAuthorization: false, // 已放弃授权
        teamMemberCount: 0,
        // 企业微信过期弹窗
        expirePopTodayIsShow: true,
        // 企业微信用户充值状态
        wxWorkIsBuy: false,
        // 企业微信购买人数上限弹窗
        limitPopTodayIsShow: false,
        // 吸顶通知弹窗数据
        mountingNoticeList: [],
        // 专业版会员
        isProVip: false,
        // 登录方式
        loginType: '',
        // 企业微信用户名
        wwAuthUser: '',
        // 日商用下载次数(新版个人会员)
        downloadCount: 0,
        sendVipModalShow: false, // 显示领取尝鲜VIP弹窗
        receivedVipModalShow: false, // 显示领取尝鲜VIP成功弹窗
        noClaimVipModalShow: false, // 老会员不可领取尝鲜VIP弹窗
        // 绑定手机号弹窗
        bindPhoneShow: false,
        // 团队成员列表
        teamMembers: 0,
        activityFlag: false, // 活动入口展示状态
        showScreenMarkeyForm: false, // 活动入口展示状态
        giftPackShow: 0,
        giftNumber: 0,
        sourceTimer: null,
        isNewCustomer: false, //是否新用户
        screenWidth: document.body.clientWidth,
        showRiskLogin: false, // 风控 登录限制
        riskLoginLimitInfo: {},
        messageCountInfo: {},
        canCreateFreeTeam: false, // 是否可以创建免费团队
        trackerBaseParams: {},
        tariffPackageParasm: {
          tariffPackageId: 0, //套餐ID
          guidExplain: '', //引导文案
        }, //试用会员套餐id
        proVipVisible: false,
        vipContactSalesFrom: {
          forward_page_name: '',
          forward_module_name: '',
        },
        scrollBarState: true, //
        scrollTimeout: null,
        currentRoutePath: new URL(location.href).pathname,
        teamEntryState:false, //是否有团队入口
        tietieRechargeVisible: false,
        origin:{
          pageName: "topbar",
          moduleName: "txinfo_buytt",
        },
        isShowSearchBar:false
      };
    },
    computed: {
      ...mapGetters({
        userInfo: 'getUserInfo',
        logregShow: 'getLogregShow',
        isLogin: 'getIsLogin',
        getIsVip: 'getIsVip',
        isPaidUser: 'getIsPaidUser',
        teams: 'getTeams',
        teamId: 'getTeamId',
        tplTdk: 'getTDK',
        rechargeShow: 'getRechargeShow',
        messageShow: 'getMessageShow',
        messagePlaceHolder: 'getMessagePlaceHolder',
        messageType: 'getMessageType',
        messageHideTime: 'getMessageHideTime',
        baiduFactor: 'getBaiduFactor',
        bodyClickSign: 'getBodyClickSign',
        teamIsExpired: 'getTeamIsExpired',
        teamGuide: 'getTeamGuide',
        funcGuide: 'getFuncGuide',
        designerType: 'getDesignerType',
        logregShowLocation: 'getLogregShowLocation',
        getLogoData: 'getLogoData',
        getHeaderActivityShow: "getHeaderActivityShow",
      }),
      logregModalShow: {
        get() {
          return Boolean(this.logregShow);
        },
        set() {
          try {
            if (this.logregShow === 1) {
              window.gio('track', 'login_popup_close_click');
            } else if (this.logregShow === 2) {
              window.gio('track', 'signup_popup_close_click');
            }
          } catch (e) {
            console.error('gio error \n' + e);
          }

          this.setLogregShow(false);
          if (sessionStorage.getItem('wxAuth')) {
            sessionStorage.removeItem('wxAuth');
          }
        },
      },
      isSvip() {
        return this.teamInfo.adminUserVipLevel === 220 && this.teamInfo.adminUserMemberExpire > Date.now();
      },
      selfStyle() {
        return this.isSvip ? 'searchBarStyle searchFlashipStyle':
        this.getHeaderActivityShow ? 'searchBarStyle searchBarAdStyle': 
        !this.isLogin ? 'searchBarStyle searchBarNoLoginStyle':  
        this.teamEntryState? 'searchBarStyle searchBarLeftStyle': 
        'searchBarStyle'
      },
      isApi() {
        window.isApi = !!sessionStorage.getItem('apiInfo');
        return !!sessionStorage.getItem('apiInfo');
      },
      isHideSearBar() {
        return (
          this.$route.path === '/clientpage' ||
          this.$route.path === '/video-cooperation' ||
          this.$route.path === '/enterpriseSaasLandPage'
        );
      },
      headerMaskShow() {
        return !!(this.teamGuide || this.funcGuide);
      },
      isYouZanStates() {
        return (
          this.userInfo.socialAccountMap &&
          this.userInfo.socialAccountMap.YOUZAN !== undefined
        );
      },
      // 企业vip落地页吸顶条显示时隐藏header
      isHideHeaderInVipPage() {
        return (
          (this.$route.path === '/price/vip' ||
            this.$route.path === '/price/company') &&
          this.scrollTop > 70
        );
      },
      // 企业微信用户
      isWorkWeixinUser() {
        return this.teamInfo && this.teamInfo.workWxFlag;
      },
      // 企业微信团队到期
      wxWorkExpire() {
        const isExpire = this.teamInfo.adminUserMemberExpire - Date.now() <= 0;
        return this.isWorkWeixinUser && isExpire && !this.expirePopTodayIsShow;
      },
      // 企业微信团队超过购买人数
      wxWorkLimit() {
        return (
          this.isWorkWeixinUser && this.wxWorkIsBuy && !this.limitPopTodayIsShow
        );
      },
      // 企业微信人数限制
      wxWorkBuyMemberLimit() {
        const isLimit = this.teamMembers - this.teamMemberCount > 0;
        return this.isWorkWeixinUser && isLimit;
      },
      // 企业微信客户端
      isWxWorkClient() {
        const ua = window.navigator.userAgent.toLowerCase();
        return (
          ua.match(/MicroMessenger/i) == 'micromessenger' &&
          ua.match(/wxwork/i) == 'wxwork'
        );
      },
      getShowPage() {
        return (
         this.scrollBarState &&
         !this.isLogin &&
          location.pathname === '/'
        );
      },
      includePage() {
        return false;
      },
      isAdministrator() {
        return this.teamInfo.teamRoleEnum === "Administrator";
      },
    },
    watch: {
      $route(to, old) {
        tracker.urlChange();
        // 三方登录成功回调
        if (localStorage.getItem('giftPack')) {
          localStorage.removeItem('giftPack');
          this.$nextTick(() => {
            this.$refs.giftPack.handleThirdpartyLogin();
          });
        }
        // 为登陆后还能跳转回原来的页面做的妥协
        if (to.fullPath.indexOf('/logreg') > -1) {
          window.referrer = old.fullPath;
        } else {
          window.referrer = '';
        }
        // 第三方登录成功后回调
        if (this.$route.query.handleLogin) {
          this.handleLoginSucceed();
          // 清除handleLogin标志
          let newQuery = JSON.parse(JSON.stringify(this.$route.query));
          delete newQuery.handleLogin;
          if (newQuery.giftPack) {
            localStorage.setItem('giftPack', true);
            delete newQuery.giftPack;
          }
          this.$router.replace({ query: newQuery });
        }
        // 隐藏顶部导航栏
        this.isCktHeaderHidden = hiddenHeaderRoute(this.$route.query).some(
          (item) => item.path === this.$route.path && !item.show,
        );
        this.isFullscreenMode = fullscreenModeRoute(this.$route.query).some(
          (item) => item.path === this.$route.path && !item.notfull,
        );
        this.showLoginEntry =
          this.$route.path.indexOf('invatationactive') === -1;
        // 设置子页面容器高度
        this.setContentHeight();
        // 设置客服按钮显示状态
        this.toggleKeFuBtn();
        this.queryPageAdminByPagePath();
        testUrl(to);

        // 处理baidu时间因子
        const baiduFactor = document.querySelector(
          'script[type="application/ld+json"]',
        );
        const path = this.$route.path;

        if (
          (path.indexOf('/sj') > -1 && path.indexOf('ke') > -1) ||
          path === '/templatecenter/themes'
        ) {
          baiduFactor && baiduFactor.remove();
        }
        const routeArr = ['/templatecenter', '/muban', '/blog', '/sj'];
        const isMatch = routeArr.some((item) => path.indexOf(item) > -1);
        if (path != '/' && !isMatch) {
          baiduFactor && baiduFactor.remove();
        }
      },
      tplTdk() {
        this.setTDK();
      },
      screenWidth() {
        this.trialExpiredTipsChange(false);
      },
      userInfo: {
        handler(v) {
          if (!v.LoginTimeOut) {
            tracker.setIsLogin(true);
            tracker.setUserInfo(v);
            if (this.noticeList && this.noticeList.length > 0) {
              let hasRead = true;
              for (let i = 0; i < v.urnCount; i++) {
                if (
                  this.noticeList[i].noticeId === this.normalNotice.noticeId
                ) {
                  hasRead = false;
                  break;
                }
              }
              if (hasRead) {
                this.normalNotice.subject = '';
                this.normalNotice.content = '';
                this.normalNotice.noticeId = 0;
              }
            }
            if (this.userInfo.vipLevel == 3) {
              this.setCopyrightPrivilege(false);
            } else {
              this.setCopyrightPrivilege(true);
            }
          } else {
            tracker.setIsLogin(false);
          }
        },
        immediate: true,
      },
      messageShow(v) {
        if (v) {
          this.$message({
            message: this.messagePlaceHolder,
            // type: this.messageType,
            duration: this.messageHideTime,
          });
        }
      },
      baiduFactor(obj) {
        let baiduFactor = document.querySelector(
          'script[type="application/ld+json"]',
        );
        const path = this.$route.path;

        if (
          (path.indexOf('/sj') > -1 && path.indexOf('ke') > -1) ||
          path === '/templatecenter/themes'
        ) {
          baiduFactor && baiduFactor.remove();
          return;
        }

        const routeArr = ['/templatecenter', '/muban', '/blog', '/sj'];
        const isMatch = routeArr.some((item) => path.indexOf(item) > -1);
        if (path != '/' && !isMatch) {
          baiduFactor && baiduFactor.remove();
          return;
        }

        if (!baiduFactor) {
          baiduFactor = document.createElement('script');
          baiduFactor.setAttribute('type', 'application/ld+json');
          document.body.appendChild(baiduFactor);
        }
        baiduFactor.innerText = JSON.stringify(obj);
      },
      isLogin(v) {
        if (v) {
          this.getCanCreateFreeTeam();
          this.getUserTeams(1);
          // this.removeUtmSource();
        } else {
          //未登录用户会度分流
          if (
            this.$route.path === '/' &&
            !window.location.host.includes('beta') &&
            !window.location.host.includes('local')
          ) {
            this.getBetaInfo();
          }
        }
      },
      teamId(val) {
        // 从新设置数量，防止切换团队时 数据没有及时更新导致弹框出现后消失的问题
        this.teamMembers = 0;
        this.setTeamRoleEnumAndIsExpired(this.teams);
        this.getTeamInfo();
        this.getTeamMembers();
        this.getTeamMemberInfo();
        this.queryTeamFunctionServiceList(val);
      },
      teams(v) {
        if (v) {
          v.forEach((t) => {
            if (
              t.adminUserMemberExpire > Date.now() &&
              t.adminUserVipLevel == 3
            ) {
              this.setCopyrightPrivilege(false);
            } else {
              this.setCopyrightPrivilege(true);
            }
          });
        }
      },
      isYouZanStates(v) {
        if (v) {
          if (!this.isYouzanCustomStates) {
            window.localStorage.setItem('isYouzanCustomStates', true);
          }
          this.isYouzanCustomStates = v;
        } else {
          this.isYouzanCustomStates = v;
          localStorage.removeItem('isYouzanCustomStates');
        }
      },
      isFullscreenMode() {
        this.trialExpiredTipsChange(this.trialExpiredTipsShow);
      },
      isNewCustomer() {
        if (this.isNewCustomer) {
          let exploreBaseData = {
            forward_page_name: '品牌首页',
            forward_module_name: '',
            popup_name: '开始免费做图引导条',
            page_url: location.href,
            is_login: this.isLogin ? '是' : '否',
            user_id: this.userInfo.userId,
            user_vip: this.userInfo.vipLevelName,
            team_id: this.teamId,
            team_name: '',
            team_vip: '',
            business_lines: 1,
            product_name: 1,
            platform: 1,
          };
          try {
            window.gio('track', 'popup_show', exploreBaseData);
          } catch (error) {}
        }
      },
      teamInfo(v) {
        tracker.setTeamInfo(v);
      },
      getShowPage(v) {
        if (v) {
          try {
            const params = {
              page_name: "宣传页",
              module_name: "底部引导条",
              bubble_name: "登录引导气泡",
              is_login: "否",
            };
            window.gio("track", "bubble_show", params);
          } catch (error) {}
        }
      },
    },
    created() {
      if (workEnv != 'local') {
        new window.$Tracker({ baseParams: {}, alias: 'cktTracker' });
      }
      if (workEnv === 'moni') {
        this.$http
          .post('/tools/checkUserVisitAuth', { apidomainv2: true })
          .then((res) => {
            const code = res.body.code;
            if (code === -103) {
              // 当前用户 IP 不在白名单中
              window.location.replace(window.location.href.replace('moni', ''));
            }
          });
      }
      this.trackerBaseParams = tracker.getBaseParams();
    },
    beforeMount() {
      this.isYouzanCustomStates =
        window.localStorage.getItem('isYouzanCustomStates') == 'true';
      this.$http
        .post('/user/logOutStatus', { client_type: 0 })
        .then()
        .finally(() => {
          this.appInit();
          this.getSysTime();
          this.getAdPosition(); // 获取广告位信息
        });
    },
    mounted() {
      // 浏览器兼容性提示窗
      const { wechat, chrome, firefox, safari, canvas, proxy, other } =
        checkBrowser;
      if (
        wechat ||
        (!chrome && !firefox && !safari) ||
        !canvas ||
        !proxy ||
        other
      ) {
        if (
          +localStorage['browser_compatible_displayed'] !== new Date().getDate()
        ) {
          this.browserError = true;
        }
      }
      // 首页导航栏应用不同样式
      this.isFullscreenMode = fullscreenModeRoute(this.$route.query).some(
        (item) => item.path === this.$route.path && !item.notfull,
      );
      // 设置客服按钮显示状态
      this.toggleKeFuBtn();
      // 隐藏顶部导航栏
      this.isCktHeaderHidden = hiddenHeaderRoute(this.$route.query).some(
        (item) => item.path === this.$route.path && !item.show,
      );
      try {
        customElements.whenDefined('ckt-cop-new-header').then(() => {
          this.isShowSearchBar =true
          if (this.isShowSearchBar) {
            setTimeout(() => {
              this.getSearchBarPosition();
            }, 200);
          }
        });
      } catch (error) {
        console.log(error)
      }
      window.onresize = () => {
        clearTimeout(this.resizeThottleTimer);
        this.resizeThottleTimer = setTimeout(() => {
          return (() => {
            // 动态设置子页面容器高度
            this.setContentHeight();
            this.screenWidth = document.body.clientWidth;
            // 通过改变isResize来触发子页面的window.onresize事件
            this.isResize = !this.isResize;
            this.setResize(Date.now());
            this.getSearchBarPosition();
          })();
        }, 300);
      };
      if (this.isLogin) {
        this.getUserTeams();
      }
      if (this.teamId > 0) {
        this.getTeamInfo();
      }

      channel.onmessage = async (msg) => {
        if (msg === 'getUserInfo') {
          let userInfo = await this.getUserInfo();
          if (!userInfo.userId) {
            this.logout();
          }
        }
      };

      // 非书签、非chuangkit域，重置seo信息
      if (
        document.referrer !== '' &&
        document.referrer.indexOf('chuangkit.com') < 0 &&
        window.location.search
      ) {
        const query = window.location.search.split('?')[1].split('&');
        query.forEach((item) => {
          const keyValue = item.split('=');
          if (keyValue[0] === 'utm_source') {
            window.localStorage.setItem('utm_source_seo', keyValue[1] || '');
          }

          if (keyValue[0] === 'utm_content') {
            window.localStorage.setItem('utm_content', keyValue[1] || '');
          }

          if (keyValue[0] === 'utm_term') {
            window.localStorage.setItem('utm_term', keyValue[1] || '');
          }
        });
        window.localStorage.setItem(
          'utm_expiretime',
          new Date(new Date().toLocaleDateString()).getTime() +
            24 * 3600 * 1000 * 15,
        );
      }

      this.sourceTimer = setTimeout(async () => {
        let query = this.$route.query;
        // 如果通过点击广告进入，存储广告来源
        if (query.utm_source) {
          window.localStorage.setItem('utm_source', query.utm_source);
          if (query.login_pop) {
            let userInfo = await this.getUserInfo();
            if (!userInfo) {
              this.setLogregShow(1);
            }
          }
        }
        //360 OCPC
        try {
          let bdVid = this.$route.query.bd_vid || '';
          let qhclickid = this.$route.query.qhclickid || '';
          let utm_source = this.$route.query.utm_source || '';
          if (qhclickid || bdVid || utm_source) {
            localStorage.setItem('ckt_ocpc_url', location.href);
            localStorage.setItem('ckt_ocpc_time', new Date().getTime());
            localStorage.setItem('ckt_ocpc', bdVid ? location.href : qhclickid);
            if (!(qhclickid || bdVid) && utm_source) {
              localStorage.setItem('ckt_utm_source', 'utmState'); //非qhclickid和bdVid进来的存个状态
            } else {
              localStorage.setItem('ckt_utm_source', ''); //qhclickid和bdVid进来的清空ckt_utm_source状态
            }
            const data = {
              gw: true,
            };
            if (bdVid) {
              data.bdVidUrl = location.href;
            }
            if (qhclickid) {
              data.qhClickId = qhclickid;
            }
            this.$http.post('/hatchery/ocpc/saveOcpcData', data).then((res) => {
              const code = res.body.code; // 状态码：200 请求成功，-201 获取失败
              if (code === 200) {
                console.log('qhclickid ocpc success');
              }
            });
          }
        } catch (e) {
          console.log('qhclickid null');
        }

        try {
          window.sessionStorage.setItem('utm_source', query.utm_source);
        } catch (e) {
          console.log('set utm_source session error');
        }
      }, 800);

      this.setFillAuthorizationState();
      customerServiceInit();
      this.getTeamMemberInfo();
      this.getTeamMembers();
    },
    methods: {
      ...mapActions({
        setUserInfo: 'setUserInfo',
        setLogregShow: 'setLogregShow',
        setIsLogin: 'setIsLogin',
        setIsPaidUser: 'setIsPaidUser',
        setIsNewSkuTestUser: 'setIsNewSkuTestUser',
        setTid: 'setTeamId',
        setResize: 'setResize',
        setRechargeShow: 'setRechargeShow',
        setDesignTester: 'setDesignTester',
        setMessageShow: 'setMessageShow',
        setBaiduFactor: 'setBaiduFactor',
        setBodyClickSign: 'setBodyClickSign',
        setTeams: 'setTeams',
        setTeamIsExpired: 'setTeamIsExpired',
        setCopyrightPrivilege: 'setCopyrightPrivilege',
        setTeamSwitch: 'setTeamSwitch',
        setSearchKeywords: 'setSearchKeywords',
        setDesignerType: 'setDesignerType',
        setLogregShowLocation: 'setLogregShowLocation',
        setAdBannerState: "setAdBannerState",
        setHeaderActivityShow: "setHeaderActivityShow",
        setTeamFunctionServiceList: 'setTeamFunctionServiceList'
      }),
      getSearchBarPosition() {
        const searchBlock = document.getElementsByClassName("header")[0];
        const newSearchDom = document.querySelector(".searchBarStyle");
        let searchBarLeft = 0;
        if (searchBlock.shadowRoot && document.body.clientWidth <= 1400) {
          const offsetLeft = searchBlock.shadowRoot.querySelector(
            ".searchBarBlockWarp",
          ).offsetLeft;
          searchBarLeft = offsetLeft + "px";
          newSearchDom.style.right = "auto";
          newSearchDom.style.left = searchBarLeft;
        } else {
          newSearchDom.style = {};
        }
      },
      showRisk(info) {
        this.showRiskLogin = true;
        this.riskLoginLimitInfo = info;
      },
      createFreeTeam() {
        this.$refs.CreateTeamModal.createTeam();
      },
      handleChangeNavScroll(distance) {
        this.$refs.mainContent.scrollTo({
          top: distance,
          behavior: 'smooth',
        });
      },
      // 判断是否可以创建免费团队
      async getCanCreateFreeTeam() {
        const { body: { data, code } = {} } =
          (await this.$http.post('/team/team/freeUseTeamPop', {
            gw: true,
          })) || {};
        this.canCreateFreeTeam = code === 200 && data;
      },
      buyTietie() {
        if (!this.isAdministrator) {
          return this.$message("请联系团队创建者购买");
        }
        this.tietieRechargeVisible = true;
      },
      paySuccessHandler() {
        this.$message("购买成功");
        this.tietieRechargeVisible = false;
        // this.queryTieTieInfo();
      },
      openCustomerServiceHandler() {
        this.contactService();
      },
      closeRisk() {
        this.showRiskLogin = false;
      },
      setGiftNumber() {
        this.giftNumber++;
      },
      giftShow(val) {
        this.giftPackShow = val;
        if (sessionStorage.getItem('designSecneNeedToBeOpen')) {
          sessionStorage.removeItem('designSecneNeedToBeOpen');
        }
      },
      isIncludeDeatail(val){
        if (val.detail?.length>0) {
          return val.detail[0]
        }
        return val
      },
      async getBetaInfo() {
        let uuid = this.getCktUuid(10);
        const res = await this.$http.get(
          `/accountcenter/garyscale/info/${uuid}`,
          {
            data: { gw: true, requestMethod: 'GET', isInclude: true },
          },
        );
        if (res.data.body.code == 200) {
          //灰度用户
          if (res.data.body.data.status == 1) {
            let hostName = window.location.host.replace(
              window.location.host,
              'beta.chuangkit.com',
            );
            window.location.replace(
              window.location.href.replace(window.location.host, hostName),
            );
          }
        }
      },
      handlelogregLoginSucceed({ type, callBack }) {
        console.log('{type, callBack}', { type, callBack });
        if (!type) return;
        this.handleLoginSucceed(type, callBack);
      },
      appInit() {
        this.queryPageAdminByPagePath();
        // 第三方登录成功后回调
        if (this.$route.query.handleLogin) {
          this.handleLoginSucceed();
          // 清除handleLogin标志
          let newQuery = JSON.parse(JSON.stringify(this.$route.query));
          delete newQuery.handleLogin;
          this.$router.replace({ query: newQuery });
        } else {
          this.getUserInfo();
        }
        testUrl(this.$route);
      },
      setLogregShowInHeader(val) {
        this.setLogregShow(val);
        this.setLogregShowLocation(1);
      },
      trialExpiredTipsChange() {
        // 强制判断，如果在个人和企业VIP 会员页，根据团队使用版过期提示进行判断显示，其他正常判断
        // 与headWrap高度相关的元素：1 header本身 2.过期提示条是否显示 3.活动入口是否显示 4，是否是首页首屏
        // 此方法执行时，活动入口元素已渲染可获取高度，过期提示未渲染，所以h+32
        const h = document
          .querySelector('.headWrap')
          .getBoundingClientRect().height;
        if (this.isFullscreenMode) {
          this.pageWrapTop = h - 66 + 'px';
        } else {
          this.pageWrapTop = h + 'px';
        }
      },
      goToTop() {
        let ele = document.querySelector('#main-content');
        if (ele) {
          const go = () => {
            let top = 0.2 * ele.scrollTop;
            if (top < 1) {
              top = 1;
            }
            ele.scrollTop = ele.scrollTop - top <= 0 ? 0 : ele.scrollTop - top;
            if (ele.scrollTop > 0) {
              window.requestAnimationFrame(go);
            }
          };
          window.requestAnimationFrame(go);
        }
      },
      // 获取系统时间
      getSysTime() {
        return new Promise((resolve) => {
          this.$http.post('/team/secondKill/getSysTime', { gw: true }).then(
            (res) => {
              if (res.body.code === 200) {
                this.sysTime = res.body.data;
                resolve(res.body.data);
              } else {
                resolve();
              }
            },
            (err) => resolve(err),
          );
        });
      },
      //获取用户消息数量
      async getUserNoticeCount(acticveId = 0) {
        const params = {
          gw: true,
          noticeKind: acticveId || 0,
        };
        const res =
          (await this.$http.post('/mbox/notice/getUserNoticeCount', params)) ||
          {};
        if (res.body.code == 200) {
          this.messageCountInfo = res.body.data;
        }
      },
      //
      queryTeamFunctionServiceList(teamId) {
        this.$http
          .post('/team/fuse/function/service/record/getFunctionServiceList', {
            team_id: teamId,
            gw: true,
          })
          .then((res) => {
            if (res.body.code === 200) {
              this.setTeamFunctionServiceList(res.body.data || []);
            }
          });
      },
      // 获取页面信息
      queryPageAdminByPagePath() {
        this.$http
          .post('/team/tools/queryPageAdminByPagePath', {
            page_path: window.location.pathname,
            gw: true,
          })
          .then((res) => {
            this.useTdkFromVuex = false;
            if (Object.keys(res.body).length === 0) return;
            if (res.body.code) return;
            //  给pc与移动端相对应的页面添加标识代码 sj- 部分
            if (window.location.pathname.indexOf('sj-') > -1) {
              // 移动端是否存在(0不存在；1存在)
              let url = location.href;
              let mUrl = url.replace('www', 'm');
              if (res.body.wxShowState === 1) {
                let link = document.createElement('link');
                link.setAttribute('rel', 'alternate');
                link.setAttribute(
                  'media',
                  'only screen and (max-width: 640px)',
                );
                link.setAttribute('href', mUrl);
                link.href = mUrl;
                document.getElementsByTagName('head')[0].appendChild(link);

                link = document.createElement('link');
                link.setAttribute('rel', 'canonical');
                link.setAttribute('href', url);
                document.getElementsByTagName('head')[0].appendChild(link);

                let m = document.createElement('meta');
                m.name = 'mobile-agent';
                m.content = `format=html5;url=${mUrl}`;
                document.getElementsByTagName('head')[0].appendChild(m);
              } else {
                let link = document.createElement('link');
                link.setAttribute('rel', 'canonical');
                link.setAttribute('href', url);
                document.getElementsByTagName('head')[0].appendChild(link);
              }
            }
            let pageAdmin = res.body.pageAdmin;
            if (Object.keys(pageAdmin).length === 0) {
              if (window.location.pathname.indexOf('/muban/') > -1) {
                this.useTdkFromVuex = true;
              }

              if (window.location.pathname.indexOf('sj-') > -1) {
                this.useTdkFromVuex = true;
              }

              if (window.location.pathname.indexOf('/tupian/') > -1) {
                this.useTdkFromVuex = true;
              }

              if (
                window.location.pathname.indexOf('/blog/') > -1 &&
                window.location.pathname.indexOf('/blog/page/') == -1
              ) {
                this.useTdkFromVuex = true;
              }
            }
            //处理百度时间因子
            if (
              (res.body.upDate != undefined && res.body.upDate != 0) ||
              (res.body.pubDate != undefined && res.body.pubDate != 0)
            ) {
              let baiduFactorCB = () => {
                setTimeout(() => {
                  let meta = document.getElementsByTagName('meta');
                  let description = meta['description'].getAttribute('content');
                  let data = {
                    '@context':
                      'https://ziyuan.baidu.com/contexts/cambrian.jsonld',
                    '@id': location.href,
                    title: document.title,
                    description: description,
                  };
                  if (res.body.upDate != 0)
                    data.upDate = new Date(res.body.upDate - -28800000)
                      .toISOString()
                      .split('.')[0];
                  if (res.body.pubDate != 0)
                    data.pubDate = new Date(res.body.pubDate - -28800000)
                      .toISOString()
                      .split('.')[0];
                  this.setBaiduFactor(data);
                }, 0);
              };
              if (this.useTdkFromVuex) {
                this.tdkCallBackQueue.push(baiduFactorCB);
              } else {
                baiduFactorCB();
              }
            }

            if (Object.keys(pageAdmin).length === 0) return;

            document.title = pageAdmin.title;
            let meta = document.getElementsByTagName('meta');
            meta['keywords'].setAttribute('content', pageAdmin.keywords);
            meta['description'].setAttribute('content', pageAdmin.description);
          });
      },
      // 处理登录/注册成功（本站/第三方）
      async handleLoginSucceed(type, callBack) {
        if (this.$route.query.dontjump) {
          this.$router.replace('/');
          this.$router.go(0);
        }
        // 新访客点击立即领取，领取尝鲜会员
        if (
          sessionStorage.getItem('clickedReceiveVip') &&
          sessionStorage.getItem('clickedReceiveVip') !== null
        ) {
          await this.receiveVip();
        }
        sessionStorage.removeItem('newIndexPageTap');
        this.loginType = type;
        let userInfo = await this.getUserInfo();
        this.automaticTriggerTask();
        // 停止第三方登录轮询
        if (sessionStorage.getItem('wxAuth')) {
          sessionStorage.removeItem('wxAuth');
        }
        if (sessionStorage.getItem('designNeedToBeOpen')) {
          let param = sessionStorage.getItem('designNeedToBeOpen');
          let url = param;
          if (param.indexOf('id') > -1) {
            if (userInfo.mem || this.isPaidUser) {
              url = `/odyssey/createDesign?t=${param.replace(/\D/g, '')}`;
            } else {
              url = `/muban/td-${param}.html`;
            }
          } else {
            url = `/odyssey/createDesign?${param}`;
          }
          sessionStorage.removeItem('designNeedToBeOpen');
          location.href = url;
        }
        // 设计师身份
        if (userInfo && userInfo.designerCheckStatus === 2) {
          const state = await this.getDesignerType();
          if (state !== 1) {
            location.href = '/designer/create';
          }
        }
        // 登录注册上报
        this.loginReport(callBack);
        channel.postMessage('getUserInfo');
      },
      gotoLogin() {
        if (sessionStorage.getItem('designSecneNeedToBeOpen')) {
          sessionStorage.removeItem('designSecneNeedToBeOpen');
        }
        this.setLogregShow(1);
        let exploreBaseData = {
          module_name: "登录引导条",
          button_name: "登录",
          page_name: "品牌首页",
          page_url: location.href,
          is_login: this.isLogin ? '是' : '否',
          user_id: this.userInfo.userId,
          user_vip: this.userInfo.vipLevelName,
          team_id: this.teamId,
          team_name: '',
          team_vip: '',
          business_lines: 1,
          product_name: 1,
          platform: 1,
        };
        // 登录埋点
        sessionStorage.setItem(
          'forward',
          JSON.stringify({
            forward_module_name: '登录引导条',
            forward_page_name: '品牌首页',
          }),
        );
        try {
          window.gio('track', 'button_click', exploreBaseData);
        } catch (error) {}
      },
      enterSence() {
        let forwardData = {
          forward_module_name: '底部通栏_随便试试',
          forward_page_name: '品牌首页',
        };
        sessionStorage.setItem('forward', JSON.stringify(forwardData));
        if (this.isLogin) {
          //根据默认场景跳转设计页
        } else {
          this.$message('请先登录');
          // let defaultSenceId = sessionStorage.getItem('defaultSenceId')
          // sessionStorage.setItem('designSecneNeedToBeOpen', `k=${defaultSenceId}`)
          this.setLogregShow(1);
          let exploreBaseData = {
            module_name: '吸底引导条',
            button_name: '随便试试',
            page_url: location.href,
            is_login: this.isLogin ? '是' : '否',
            user_id: this.userInfo.userId,
            user_vip: this.userInfo.vipLevelName,
            team_id: this.teamId,
            team_name: '',
            team_vip: '',
            business_lines: 1,
            product_name: 1,
            platform: 1,
          };
          try {
            window.gio('track', 'button_click', exploreBaseData);
          } catch (error) {}
        }
      },
      // 用户登录自动触发发送通知
      async automaticTriggerTask() {
        // 1.必须保证用户登录成功之后再调用接口
        // 2.用户登录成功后,同一天只调用一次,接口对于用户是透明的,不需要根据 code 进行提示不需要关心返回参数
        // 3.当 code=200 时开始轮询调用 getSendNoticeState 接口
        const userInfo = this.userInfo;
        if (userInfo.userId) {
          const sysTime = await this.getSysTime();
          if (sysTime) {
            const time = dayjs(sysTime).format('YYYY-MM-DD');
            const flag =
              localStorage[`automaticTriggerTask_${userInfo.userId}`];

            if (!flag || flag !== time) {
              localStorage[`automaticTriggerTask_${userInfo.userId}`] = time;
              const { code } = await this.$http.post(
                '/login/automaticTriggerTask',
                {
                  apidomainv2: true,
                },
              );
              if (code !== 200) return;
              const polling = () => {
                if (Date.now() - sysTime > 5 * 60 * 1000) {
                  clearTimeout(this.getSendNoticeStateTimer);
                  return;
                }
                this.getSendNoticeStateTimer = setTimeout(() => {
                  const { code } = this.$http.post(
                    '/login/getSendNoticeState',
                    {
                      apidomainv2: true,
                    },
                  );
                  // 操作码：-2未登录;-1没有查询到状态；0准备处理；1发送成功；2不需要发送
                  // 1.当code=-2或code=2时停止轮询操作
                  // 2.当code=-1或code=0时继续轮询操作
                  // 3.当code=1时需调用getUserInfo和getUserNotice接口|
                  // 4.轮询时间阈值为5分钟，超过阈值不再进行轮询操作
                  switch (code) {
                    case 1:
                      this.getUserInfo();
                      break;
                    case -1:
                    case 0:
                      polling();
                      break;
                    case -2:
                    case 2:
                      clearTimeout(this.getSendNoticeStateTimer);
                      break;
                  }
                }, 1000);
              };
              polling();
            }
          }
        }
      },
      getUserInfo() {
        return new Promise((resolve) => {
          this.$http
            .post('/accountcenter/user/getAccountUserInfo', {
              client_type: 0,
              gw: true,
            })
            .then(
              (res) => {
                const result = res.body;
                if (result.LoginTimeOut) {
                  this.setUser(null);
                  // 风控
                  if (result.code === 10060) {
                    this.showRisk(result.data);
                  }
                  resolve();
                  return;
                }
                // 分流
                if (result && !this.isApi) {
                  if (result.isBeta !== undefined) {
                    const isBeta = !!result.isBeta;
                    sessionStorage.setItem('isBeta', result.isBeta);
                    const env = process.env.VUE_APP_WORK_ENV; // moni beta prod
                    let href = '';
                    let obj = {
                      prod: {
                        true: 'https://beta.chuangkit.com',
                      },
                      beta: {
                        false: 'https://www.chuangkit.com',
                      },
                    };
                    if (obj[env] && obj[env][String(isBeta)]) {
                      href = obj[env][String(isBeta)];
                    }
                    if (href) {
                      window.location.href =
                        href +
                        window.location.pathname +
                        window.location.search;
                    }
                  }
                }
                // 设置userInfo信息
                this.setUser(result.userInfo);
                // 设置用户当前状态
                this.setCurTeamState();

                // 首次请求的未读消息数（读取消息后会再发一次用户信息请求），用于控制大弹窗显示
                if (this.userInfo.urnCount > 0) {
                  this.getUserNotice();
                  this.hasUrnAtFirst = true;
                }
                this.getVipDetail();
                resolve(result.userInfo);
              },
              (res) => {
                resolve(res);
                console.error('getUserInfo error');
              },
            );
        });
      },
      // 设置userInfo
      setUser(result) {
        this.setUserInfo(result);
        if (result === null) return;
        // 设计师
        if (result.designerCheckStatus === 2) {
          this.getDesignerType();
        }

        // 该名称用于统计，修改前需知会运营、市场等相关人员
        const vipLevel = userTypeMap[result.vipLevel];
        // 上报用户的职业行业信息
        this.getUserProfessional();

        try {
          gio('setUserId', result.userId);
          gio('app.set', 'is_new_user', result.isNewUser);
          gio('app.set', 'is_vip', this.getIsVip);
          gio('app.set', 'have_bought_vip', result.memberExpire !== null); // 购买过会员
          gio('app.set', 'vip_type', vipLevel); // 会员类型
        } catch (err) {
          throw err;
        }
      },
      // 设置用户当前团队的状态
      setCurTeamState() {
        const userInfo = this.userInfo;
        if (userInfo.curTid > 0) {
          const teamLidt = this.teams;
          for (let i = 0; i < teamLidt.length; i++) {
            if (teamLidt[i].teamId === userInfo.curTid) {
              // 团队状态(Trial试用中;Pay付费使用中;Lack已欠费;Pause已停用)
              userInfo.curTeamState = teamLidt[i].teamState;
              this.setUserInfo(userInfo);
            }
          }
        }
      },
      // 获取用户通知
      getUserNotice() {
        this.$http.post('/user/getUserNotice').then((res) => {
          if (res.body.code) return; // -1 未登录 0无通知记录

          this.noticeList = res.body.noticeList;

          let normal = false;
          let big = false;
          let notice = false;
          let template = false;
          let mountingNoticeList = [];

          // 显示未读消息中每种类型的最新消息
          const len = this.noticeList.length;
          for (let i = 0; i < len; i++) {
            // 通知类型 0：普通通知；1 ：大弹框；2：浮层弹框
            if (
              this.noticeList[i].noticeType !== 0 &&
              this.noticeList[i].deadLine &&
              this.noticeList[i].deadLine.time < this.userInfo.curTime.time
            ) {
              continue;
            }
            if (this.noticeList[i].noticeType === 0) {
              // 普通通知
              if (normal || this.noticeList[i].hasRead) continue;
              this.normalNotice.subject = this.noticeList[i].subject;
              this.normalNotice.content = this.noticeList[i].content;
              this.normalNotice.noticeId = this.noticeList[i].noticeId;
              normal = true;
            } else if (this.noticeList[i].noticeType === 1) {
              // 大弹框
              if (big || this.noticeList[i].hasRead) continue;
              this.bigNoticeShow = true;
              this.backgroundImage = 'url(' + this.noticeList[i].imgUrl + ')';
              this.bigNoticeHref = this.noticeList[i].noticeUrl;
              this.bigNoticeId = this.noticeList[i].noticeId;
              big = true;
            } else if (this.noticeList[i].noticeType === 2) {
              // 浮层弹框
              if (notice || this.noticeList[i].hasRead) continue;
              this.notice = this.noticeList[i];
              this.noticeShow = true;
              notice = true;
              this.setNoticeAsHasRead(this.noticeList[i].noticeId);
            } else if (this.noticeList[i].noticeType === 3) {
              // 模板消息
              if (template || this.noticeList[i].hasRead) continue;
              this.templateMessageInfo = this.noticeList[i];
              this.templateMessageShow = true;
              template = true;
            }
          }

          // 吸顶消息单独取出，不参与其他消息的条件
          for (let i = 0; i < this.noticeList.length; i++) {
            const item = this.noticeList[i];
            if (!item.hasRead && item.noticeType === 4) {
              mountingNoticeList.push(this.noticeList[i]);
            }
          }
          this.mountingNoticeList = mountingNoticeList.reverse();
        });
      },
      // 新的团队信息
      getTeamInfo() {
        return new Promise((resolve) => {
          let data = {
            teamId: this.teamId,
            gw: true,
          };
          this.$http
            .get('/team/fuse/team/info', {
              params: data,
              data: { gw: true, template: true, nottrans: true },
            })
            .then((res) => {
              // 状态码(-1:参数错误；-2未登录；-3权限不足；-4获取团队角色出错；-5获取团队信息出错；200获取成功)
              switch (res.body.code) {
                case 200:
                  this.teamInfo = res.body.data;
                  // 判断企业微信团队过期弹窗今天是否已显示
                  const expirePoplastShowDate =
                    localStorage.getItem(
                      `expirePopTodayIsShow_${this.teamId}`,
                    ) || 0;
                  this.expirePopTodayIsShow =
                    Date.now() - expirePoplastShowDate < 24 * 3600 * 1000;
                  resolve(res.body.data.teamRoleEnum);
                  if (
                    process.env.VUE_APP_WORK_ENV === 'local' ||
                    process.env.VUE_APP_WORK_ENV === 'beta' ||
                    process.env.VUE_APP_WORK_ENV === 'moni'
                  )
                    return;
                  if (res.body.data && res.body.data.secondDomain) {
                    let secondDomain = res.body.data.secondDomain;
                    let newHostname = this.replaceSubdomain(secondDomain);
                    if (!newHostname) return;
                    window.location.href =
                      'https://' +
                      newHostname +
                      window.location.pathname +
                      window.location.search;
                  } else {
                    let secondDomain =
                      workEnv == 'prod'
                        ? 'www'
                        : workEnv == 'moni'
                          ? 'moniwww'
                          : '';
                    let newHostname = this.replaceSubdomain(secondDomain);
                    if (!newHostname) return;
                    window.location.href =
                      'https://' +
                      newHostname +
                      window.location.pathname +
                      window.location.search;
                  }
                  break;
                default:
                  break;
              }
            });
        });
      },
      replaceSubdomain(domation) {
        if (!domation) return '';
        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        if (parts.length >= 3) {
          if (parts[0] == domation) return '';
          parts[0] = domation;
          const newHostname = parts.join('.');
          return newHostname;
        }
        return hostname;
      },

      // 设置团队id
      setTeamId(val) {
        this.setTid(val);
      },
      // 设置团队成员身份和团队是否过期
      setTeamRoleEnumAndIsExpired(data) {
        if (this.teamId === 0) {
          this.setTeamIsExpired(false);
        } else {
          data.forEach((item) => {
            if (item.teamId === this.teamId) {
              this.isAdmin = item.teamRoleEnum === 'Administrator';
              this.setTeamIsExpired(item.state === 1);
            }
          });
        }
      },
      setTDK() {
        if (!this.useTdkFromVuex) return;
        document.title = this.tplTdk.title;
        let meta = document.getElementsByTagName('meta');
        meta['keywords'].setAttribute('content', this.tplTdk.keywords);
        meta['description'].setAttribute('content', this.tplTdk.description);
        let tdkCallBack = this.tdkCallBackQueue.shift();
        tdkCallBack && tdkCallBack();
      },
      // 设置userInfo
      setUser(result) {
        this.setUserInfo(result);
        if (result === null) return;
        // 设计师
        if (result.designerCheckStatus === 2) {
          this.getDesignerType();
        }

        // 该名称用于统计，修改前需知会运营、市场等相关人员
        const vipLevel = userTypeMap[result.vipLevel];
        // 上报用户的职业行业信息
        this.getUserProfessional();

        try {
          gio('setUserId', result.userId);
          gio('app.set', 'is_new_user', result.isNewUser);
          gio('app.set', 'is_vip', this.getIsVip);
          gio('app.set', 'have_bought_vip', result.memberExpire !== null); // 购买过会员
          gio('app.set', 'vip_type', vipLevel); // 会员类型
        } catch (err) {
          throw err;
        }
      },
      // 设置用户当前团队的状态
      setCurTeamState() {
        const userInfo = this.userInfo;
        if (userInfo.curTid > 0) {
          const teamLidt = this.teams;
          for (let i = 0; i < teamLidt.length; i++) {
            if (teamLidt[i].teamId === userInfo.curTid) {
              // 团队状态(Trial试用中;Pay付费使用中;Lack已欠费;Pause已停用)
              userInfo.curTeamState = teamLidt[i].teamState;
              this.setUserInfo(userInfo);
            }
          }
        }
      },
      // 获取用户行业职业信息
      getUserProfessional() {
        this.$http
          .post('/accountcenter/user/getUserProfessional', { gw: true })
          .then((res) => {
            if (res.body.code == 200) {
              const profession = res.body.data && res.body.data.profession;
              const industry = res.body.data && res.body.data.industry;
              if (!profession && !industry) return;
              try {
                // industry 职业  profession 行业
                gio('people.set', 'Occupation', profession);
                gio('people.set', 'industry', industry);
              } catch (err) {
                throw err;
              }
            }
          });
      },
      // 获取用户会员详情信息
      getVipDetail() {
        if (!this.isLogin) return;

        this.$http
          .post('/vip/vipUser/getVipDetail', { gw: true })
          .then((res) => {
            if (res.body.code === 200) {
              const { currentUser, teamAdministrator } = res.body.data;
              // 获取是否是专业版
              this.isProVip = teamAdministrator.premiumEnterprise === 1;
              // 获取是否是付费用户
              const curTime = this.userInfo.curTime.time;
              const isPaidUser =
                (currentUser.vipLevel > 0 &&
                  currentUser.memberExpire > curTime) ||
                (teamAdministrator.vipLevel > 0 &&
                  teamAdministrator.memberExpire > curTime);
              this.setIsPaidUser(isPaidUser);
              // 获取试用版过期时间
              this.vipTrailExpireDate = teamAdministrator.vipTrailExpireDate;
              // 个人会员商用下载次数（0:非会员用户；-1:下载无限制用户；3次；100次）
              this.downloadCount = teamAdministrator.downloadDaliyCount;
            } else {
              this.$message(
                `获取会员详情信息失败，错误码：${res.body.code}，错误信息：${res.body.msg}`,
              );
            }
          });
      },
      // 获取用户通知
      getUserNotice() {
        this.$http.post('/user/getUserNotice').then((res) => {
          if (res.body.code) return; // -1 未登录 0无通知记录

          this.noticeList = res.body.noticeList;

          let normal = false;
          let big = false;
          let notice = false;
          let template = false;
          let mountingNoticeList = [];

          // 显示未读消息中每种类型的最新消息
          const len = this.noticeList.length;
          for (let i = 0; i < len; i++) {
            // 通知类型 0：普通通知；1 ：大弹框；2：浮层弹框
            if (
              this.noticeList[i].noticeType !== 0 &&
              this.noticeList[i].deadLine &&
              this.noticeList[i].deadLine.time < this.userInfo.curTime.time
            ) {
              continue;
            }
            if (this.noticeList[i].noticeType === 0) {
              // 普通通知
              if (normal || this.noticeList[i].hasRead) continue;
              this.normalNotice.subject = this.noticeList[i].subject;
              this.normalNotice.content = this.noticeList[i].content;
              this.normalNotice.noticeId = this.noticeList[i].noticeId;
              normal = true;
            } else if (this.noticeList[i].noticeType === 1) {
              // 大弹框
              if (big || this.noticeList[i].hasRead) continue;
              this.bigNoticeShow = true;
              this.backgroundImage = 'url(' + this.noticeList[i].imgUrl + ')';
              this.bigNoticeHref = this.noticeList[i].noticeUrl;
              this.bigNoticeId = this.noticeList[i].noticeId;
              big = true;
            } else if (this.noticeList[i].noticeType === 2) {
              // 浮层弹框
              if (notice || this.noticeList[i].hasRead) continue;
              this.notice = this.noticeList[i];
              this.noticeShow = true;
              notice = true;
              this.setNoticeAsHasRead(this.noticeList[i].noticeId);
            } else if (this.noticeList[i].noticeType === 3) {
              // 模板消息
              if (template || this.noticeList[i].hasRead) continue;
              this.templateMessageInfo = this.noticeList[i];
              this.templateMessageShow = true;
              template = true;
            }
          }

          // 吸顶消息单独取出，不参与其他消息的条件
          for (let i = 0; i < this.noticeList.length; i++) {
            const item = this.noticeList[i];
            if (!item.hasRead && item.noticeType === 4) {
              mountingNoticeList.push(this.noticeList[i]);
            }
          }
          this.mountingNoticeList = mountingNoticeList.reverse();
        });
      },
      //  获取团队信息
      getUserTeams(firstFlag = 0) {
        let resquestUrl = '/team/team/getUserTeams';
        let params = {
          gw: true,
        };
        const ua = window.navigator.userAgent.toLowerCase();
        // 微信客户端
        if (
          ua.match(/MicroMessenger/i) == 'micromessenger' &&
          ua.match(/wxwork/i) == 'wxwork'
        ) {
          resquestUrl = '/team/team/getUserTeamsForWorkWx';
          params.is_first_login = firstFlag;
        }
        return new Promise((resolve) => {
          this.$http.post(resquestUrl, params).then(
            (res) => {
              // -2未登录；-103用户无团队；200获取成功
              const { code, data } = res.body;
              if (code === 200) {
                this.setTeams(data);
                this.setTeamRoleEnumAndIsExpired(data);
                resolve(data);
              } else if (code === 103) {
                this.setTeams([]);
              } else {
                resolve();
              }
            },
            () => resolve(),
          );
        });
      },
      // 新的团队信息
      getTeamInfo() {
        return new Promise((resolve) => {
          let data = {
            teamId: this.teamId,
            gw: true,
          };
          this.$http
            .get('/team/fuse/team/info', {
              params: data,
              data: { gw: true, template: true, nottrans: true },
            })
            .then((res) => {
              // 状态码(-1:参数错误；-2未登录；-3权限不足；-4获取团队角色出错；-5获取团队信息出错；200获取成功)
              switch (res.body.code) {
                case 200:
                  this.teamInfo = res.body.data;
                  // 判断企业微信团队过期弹窗今天是否已显示
                  const expirePoplastShowDate =
                    localStorage.getItem(
                      `expirePopTodayIsShow_${this.teamId}`,
                    ) || 0;
                  this.expirePopTodayIsShow =
                    Date.now() - expirePoplastShowDate < 24 * 3600 * 1000;
                  resolve(res.body.data.teamRoleEnum);
                  if (process.env.VUE_APP_WORK_ENV === 'local'|| process.env.VUE_APP_WORK_ENV === 'moni') return;
                  if (res.body.data && res.body.data.secondDomain) {
                    let secondDomain = res.body.data.secondDomain;
                    let newHostname = this.replaceSubdomain(secondDomain);
                    if (!newHostname) return;
                    window.location.href =
                      'https://' +
                      newHostname +
                      window.location.pathname +
                      window.location.search;
                  } else {
                    let secondDomain =
                      workEnv == 'beta'
                        ? 'beta'
                        : workEnv == 'prod'
                          ? 'www'
                          : workEnv == 'moni'
                            ? 'moniwww'
                            : `${workEnv}-www`;
                    let newHostname = this.replaceSubdomain(secondDomain);
                    if (!newHostname) return;
                    window.location.href =
                      'https://' +
                      newHostname +
                      window.location.pathname +
                      window.location.search;
                  }
                  break;
                default:
                  break;
              }
            });
        });
      },
      replaceSubdomain(domation) {
        const hostname = window.location.hostname;
        const parts = hostname.split('.');
        if (parts.length >= 3) {
          if (parts[0] == domation) return '';
          parts[0] = domation;
          const newHostname = parts.join('.');
          return newHostname;
        }
        return hostname;
      },
      // 获取用户真实的最大团队成员数
      // 原因：为配合试用版团队最大成员数都为虚拟的20人
      getTeamMemberInfo() {
        this.$http
          .post('/team/team/getTeamMemberInfo', { gw: true })
          .then((res) => {
            if (res.body.code === 200) {
              this.teamMemberCount = res.body.data;
            }
          });
      },
      // 获取团队的成员信息
      getTeamMembers() {
        this.$http
          .post('/team/team/getTeamMembers', { gw: true })
          .then((res) => {
            if (res.body.code === 200) {
              this.teamMembers = (res.body.data || []).length;
            }
          });
      },
      // 设置团队id
      setTeamId(val) {
        this.setTid(val);
      },
      // 设置团队成员身份和团队是否过期
      setTeamRoleEnumAndIsExpired(data) {
        if (this.teamId === 0) {
          this.setTeamIsExpired(false);
        } else {
          data.forEach((item) => {
            if (item.teamId === this.teamId) {
              this.isAdmin = item.teamRoleEnum === 'Administrator';
              this.setTeamIsExpired(item.state === 1);
            }
          });
        }
      },
      setTDK() {
        if (!this.useTdkFromVuex) return;
        document.title = this.tplTdk.title;
        let meta = document.getElementsByTagName('meta');
        meta['keywords'].setAttribute('content', this.tplTdk.keywords);
        meta['description'].setAttribute('content', this.tplTdk.description);
        let tdkCallBack = this.tdkCallBackQueue.shift();
        tdkCallBack && tdkCallBack();
      },
      // 获取设计师类型
      getDesignerType() {
        return new Promise((resolve) => {
          this.$http
            .post('/contentcopyright/designer/getDesignerInfo', { gw: true })
            .then(
              (res) => {
                const { type, superState } = res.body.data;
                this.setDesignerType(type);
                resolve(superState);
              },
              () => resolve(),
            );
        });
      },
      // 登出
      logout() {
        channel.postMessage('getUserInfo');
        this.setIsLogin(false);
        this.sSOLogout();
      },
      sSOLogout() {
        sSOLogout().then(() => {
          window.localStorage.removeItem('currentDataClientType');
          window.localStorage.removeItem('newUserGuideStep');
          window.localStorage.removeItem('accessToken')
          window.gio('clearUserId');
          this.teamInfo = {};
          location.href = '/designtools/designindex';
        });
      },
      // 设置子页面容器高度
      async setContentHeight() {
        let height = document.body.clientHeight;
        if (window.jsbridge) {
          height = height || (await getBodyHeight());
        }
        if (this.$route.path.includes('resetpwdbyemail')) {
          height = height > 735 ? height : 735;
        }
        this.contentHeight = height + 'px';
        if (
          height < 730 &&
          this.$route.path != '/' &&
          this.$route.path.substring(0, 15) != '/templatecenter' && //模板中心也不能这样，不然会有俩滚动条
          this.$route.path.substring(0, 12) != '/designtools' &&
          this.$route.path !== '/koutu' &&
          !this.$route.path.includes('/help/') &&
          !this.$route.path.includes('/landingpage/pc')
        ) {
          this.router_view_style = {
            position: 'relative',
          };
        } else {
          this.router_view_style = {};
        }
      },
      // 关闭大弹窗
      handleCloseBigNoticeModal(noticeId, dontCount = false) {
        this.bigNoticeShow = false;
      },
      // 设置消息已读
      setNoticeAsHasRead(noticeId = 0, dontCount = false) {
        const params = {
          noticeId: noticeId,
          gw: true,
        };
        this.$http
          .post('/mbox/notice/setNoticeAsHasRead', params)
          .then((res) => {
            // 200:设置为已读成功 -1:未登录 -2:设置失败
            let code = res.body.code;
            if (code === -401) {
              this.$emit('promptShow', {
                promptKind: 'error',
                promptText: '请登录',
              });
              this.$router.push('/logreg');
            }
          });
      },
      // 点击模板消息弹框
      handleClickTemplateMessage(message, jump) {
        if (!jump) {
          this.templateMessageShow = false;
          return;
        }
        this.setNoticeAsHasRead(message.noticeId);
      },
      // 关闭登录弹窗
      handleCloseSignModal() {
        capDestroy();
        // 点击新访客落地页按钮，关闭时弹出送尝鲜会员弹窗
        if (
          sessionStorage.getItem('newIndexPageTap') &&
          sessionStorage.getItem('newIndexPageTap') !== null
        ) {
          this.sendVipModalShow = true;
        } else {
          // 删除登录后将自动打开的模板数据
          sessionStorage.removeItem('designNeedToBeOpen');
        }
      },
      async receiveVip() {
        try {
          await this.judgeIfIsNewUser();
          await this.checkPcUserAwardState();
          await this.activityAwardVip();
        } catch (error) {
          console.log('新客户送尝鲜会员出错', error);
        }
        return true;
      },
      judgeIfIsNewUser() {
        return new Promise((resolve, reject) => {
          this.$http
            .post('/user/judgeIfIsNewUser', { apidomainv2: true })
            .then((res) => {
              // 200:成功 -101:未登录
              if (res.body.code === 200) {
                if (res.body.data) {
                  // 判断新注册用户是否可以领会员
                  resolve();
                } else {
                  sessionStorage.setItem('showNoClaimVipModal', true);
                  sessionStorage.removeItem('clickedReceiveVip');
                  reject();
                }
              }
              if (res.body.code === -101) {
                this.$emit('promptShow', {
                  promptKind: 'error',
                  promptText: '请登录',
                });
                this.setLogregShow(1);
                reject();
              }
            });
        });
      },
      // 检验是否可以送会员
      checkPcUserAwardState() {
        return new Promise((resolve, reject) => {
          this.$http
            .post('/VIPActive/checkPcUserAwardState', { apidomainv2: true })
            .then((res) => {
              // 200:成功 -2:未登录
              let code = res.body.code;
              if (code === 200) {
                if (res.body.data) {
                  // data为true,可以送会员
                  resolve();
                } else {
                  sessionStorage.setItem('showNoClaimVipModal', true);
                  sessionStorage.removeItem('clickedReceiveVip');
                  reject();
                }
              }
            });
        });
      },
      // 新用户领取三天会员试用
      async activityAwardVip() {
        const res = await this.$http.post('/VIPActive/activityAwardVip', {
          apidomainv2: true,
        });
        // 200:成功
        if (res.body.code === 200) {
          console.log('领取成功');
          sessionStorage.setItem('receiveVipSuccessModal', true);
          sessionStorage.removeItem('clickedReceiveVip');
        } else {
          throw new Error();
        }
      },
      // 关闭免费领尝鲜会员弹窗
      handleCloseSendVipModal(type = 1) {
        // type:1->关闭弹窗或倒计时结束 2->点击立即领取,打开登录弹窗
        let param = {
          pop_up_type: '新用户注册挽留弹窗',
          operation: '关闭',
        };
        if (type === 2) {
          param.operation = '立即领取';
          this.setLogregShow(1);
          this.setLogregShowLocation(6);
          sessionStorage.setItem('clickedReceiveVip', true); // 点击立即领取存储状态，只有点过立即领取的新客户注册后可领取尝鲜会员
          sessionStorage.setItem('onReceiveVipTap', true); // 存储一个表示可以重复回到领取会员弹框的值
        } else {
          sessionStorage.removeItem('designNeedToBeOpen');
          sessionStorage.removeItem('onReceiveVipTap');
          sessionStorage.removeItem('clickedReceiveVip');
        }
        window.gio('track', `bind_cell_phone_pop_up`, param);
        this.sendVipModalShow = false;
      },
      // 关闭领尝鲜会员成功弹窗
      handleCloseReceivedVipModal() {
        this.receivedVipModalShow = false;
      },
      // 老用户不可领会员弹窗
      handleCloseNoClaimVipModal() {
        this.noClaimVipModalShow = false;
      },
      // 关闭浏览器兼容性提示窗
      closeBrowserError() {
        this.browserError = false;
        localStorage['browser_compatible_displayed'] = new Date().getDate();
      },
      // 显示团队邀请好友
      handleInviteTeam() {
        this.teamInvitePopShow = true;
      },
      // 关闭团队邀请好友
      handleCloseVipTeamInvite() {
        this.teamInvitePopShow = false;
      },
      ChangeTheEntrance(v) {
      this.setAdBannerState(v)
      // 点击活动入口图片显示或关闭
      if (v) {
        this.activityFlag = true
        this.setHeaderActivityShow(true);
        this.leftAsideStyles = {
          top: '91px',
          height: "calc(100vh - 91px)"
        }
      } else {
        this.activityFlag = false
        this.setHeaderActivityShow(false);
        this.leftAsideStyles ={}
      }
    },
      // 打开会员权益和购买弹窗
      async showrightsAndBuyPop(data) {
        console.log('showrightsAndBuyPop', data);
        // 企业微信团队不支持购买
        if (this.isWorkWeixinUser) {
          this.$message(
            '企业微信团队暂不支持升级，详情请联系客服，400-871-8211',
          );
          return;
        }
        // 企业专业版不支持续费
        if (this.isProVip) {
          this.$message(
            '您当前为旗舰版，暂无法购' +
              data.name +
              ',可扫码联系顾问进行续费或咨询',
          );
        }
        // 非团队创建者不可购买
        if (this.teamInfo.teamRoleEnum === undefined) {
          await this.getTeamInfo();
        }
        if (this.teamInfo.teamRoleEnum !== 'Administrator') {
          this.$message('请联系团队创建者进行购买');
          return;
        }
        if (data) {
          this.vippopParams = {
            level: data.level,
            duration: data.duration,
            locationFlag: data.locationFlag,
            downloadCount: ~~data.downloadCount,
            source: {...data.source, from: data.source?.from || localStorage.getItem('utm_source')},
            timeIndex: data.timeIndex,
            defaultPersonNum: data.defaultPersonNum,
            activeFeatureIndex: data.index,
            packageLevel:data.packageLevel,
            packageId:data.packageId,
            standardOrEnterprise:data.standardOrEnterprise
          };
          this.tariffPackageParasm = data.tariffPackageParasm;
          if (data.index) {
            this.showBuyPop = false;
          }
        }
        this.rightsAndBuyPopShow = Date.now();
      },
      // 关闭企业微信团队弹窗
      closeWxWorkPop() {
        if (this.wxWorkLimit) {
          this.limitPopTodayIsShow = false;
          localStorage.setItem(
            `limitPopTodayIsShow_${this.teamId}`,
            Date.now(),
          );
        }
        localStorage.setItem(`expirePopTodayIsShow_${this.teamId}`, Date.now());
        this.expirePopTodayIsShow = false;
      },
      // 关闭授权信息弹框
      cancelAuthorizationPop() {
        this.authorizationPopShow = false;
        this.isCloseAuthorization = true;
      },
      // 检测用户是否填写授权书
      checkUserFillAuthorizationState() {
        const params = {
          gw: true,
        };
        return new Promise((resolve) => {
          this.$http
            .post(
              '/contentcopyright/user/authorization/checkUserFillAuthorizationState',
              params,
            )
            .then(
              (res) => {
                if (res.body.code === 200) {
                  resolve(res.body.data);
                }
              },
              (err) => resolve(err),
            );
        });
      },
      // 获取授权书类型
      getAuthorizationType() {
        const params = {
          gw: true,
        };
        return new Promise((resolve) => {
          this.$http
            .post(
              '/contentcopyright/user/authorization/getUserFillAuthorization',
              params,
            )
            .then(
              (res) => {
                if (res.body.code === 200) {
                  if (res.body.data) {
                    const data = JSON.parse(res.body.data);
                    resolve(data);
                  }
                }
              },
              (err) => resolve(err),
            );
        });
      },
      // 设置授权书填写状态
      async setFillAuthorizationState() {
        this.cantFillAuthorization =
          await this.checkUserFillAuthorizationState();
        const authorInfo = await this.getAuthorizationType();
        // 老用户没有authorizationType默认重新填写授权书
        const authorType = authorInfo.authorizationType;
        this.isFillAuthorization =
          this.cantFillAuthorization && authorType !== undefined;
        this.isCloseAuthorization = authorInfo.enableCloseLimit === 1;
      },
      // 打开授权书弹窗
      setAuthorizationPopShow(isPersonalVip) {
        this.authorizationPopShow = true;
        this.isPersonalVip = isPersonalVip;
      },
      // 授权书填写成功
      fillAuthorizationSuccess() {
        this.authorizationPopShow = false;
        this.isFillAuthorization = true;
      },
      // 获取广告位信息
      getAdPosition() {
        // position: 212:导航栏logo旁广告位, 213:模板中心搜索关键词顶部广告位, 214:会员套餐广告位
        let data = {
          position: 214,
          gw: true,
        };
        this.$http
          .post('/team/tools/getWebSiteAdByPosition', data)
          .then((res) => {
            // -101没有找到指定的位置标识, -105该位置的广告位没有创建, 200 成功
            if (res.body.code === 200) {
              this.adPositionInfo = res.body.data;
            }
          });
      },
      // 清除广告来源标志
      removeUtmSource() {
        let utm_source = window.localStorage.getItem('utm_source');
        if (utm_source) {
          const a_hours = 1000 * 60 * 60; //  一小时, 注册时间与当前四相差1小时为老用户
          if (Date.now() - this.userInfo.regTime > a_hours) {
            window.localStorage.removeItem('utm_source'); // 新注册成功,删除标志
          }
        }
      },
      bodyClick() {
        this.setBodyClickSign(!this.bodyClickSign);
      },
      // 弹窗显示
      promptShow(options) {
        if (options) {
          this.$message({
            message: options.promptText || '操作成功',
            duration: options.hideTime || 1500,
          });
        }
      },
      getCktUuid(n) {
        // 生成n位随机数字
        const cktUuid =
          'ckt' + parseInt((Math.random() + 1) * Math.pow(10, n - 1));
        if (window.localStorage.getItem('cktUuid')) {
          return window.localStorage.getItem('cktUuid');
        } else {
          window.localStorage.setItem('cktUuid', cktUuid);
          return cktUuid;
        }
      },
      contactService(source, groupId = '') {
        const userId =
          (this && this.userInfo && this.userInfo.userId) || '未登录';
        const nickname =
          (this && this.userInfo && this.userInfo.nickname) || '未登录';
        const vipLevelName =
          (this && this.userInfo && this.userInfo.vipLevelName) || '普通用户';
        const webToken = userId === '未登录' ? this.getCktUuid(10) : userId;
        window.udeskShowPanel({
          userId,
          nickname,
          vipLevelName,
          webToken,
          source,
          groupId,
        });
      },
      // 客服按钮显示隐藏
      toggleKeFuBtn(status = true) {
        //控制显示隐藏客服按钮
        let bandList = [
          '/',
          'sj-',
          '/muban/',
          '/tupian/',
          'templatecenter',
          'sucaiku',
          'tupianku',
          'beijing',
          'tukuso',
          'shejisucai',
          'imageso',
          'api',
          '/image',
          'tcrf-',
        ];
        let _inArray = (str, arr) => {
          return arr.some((v) => {
            return str.indexOf(v) > -1;
          });
        };
        if (this.$route.path.indexOf('print') > -1) {
          //隐藏老的客服入口更换新的客服入口
          status = false;
        } else if (this.$route.path.includes('/help/')) {
          status = false;
        } else if (_inArray(this.$route.path, bandList)) {
          status = false;
        } else {
          status = true;
        }
        let btn = document.querySelector('.customerServiceBtn');
        if (btn) {
          if (!status) btn.style.display = 'none';
          else btn.style.display = 'block';
        } else {
          // console.log("ss:",status)
        }
      },
      handleScroll(e) {
        this.scrollTop = e.target.scrollTop;
        if (this.$route.path === '/') {
          this.scrollBarState = false;
          clearTimeout(this.scrollTimeout);
          this.scrollTimeout = setTimeout(() => {
            this.scrollBarState = true;
          }, 600);
          if (this.scrollTop > 924) {
            this.isNewCustomer = true;
          } else {
            this.isNewCustomer = false;
          }
        }
      },
      changeScrollTop(value) {
        if (typeof value === 'number') {
          this.scrollTop = value;
        }
      },
      // 消息通知 -立即续费埋点
      buriedPoint() {
        try {
          window.gio('track', 'rebuy_buy');
        } catch (error) {
          console.log(error);
        }
      },
      // 登录注册埋点
      loginReport(callBack) {
        // 登录/注册方式
        const TYPE_MAP = {
          'https://graph.qq.com/': 'qq',
          'https://api.weibo.com/': '微博',
          'https://openapi.baidu.com/': '百度',
          'https://open.work.weixin.qq.com/': '企业微信',
          'https://oapi.dingtalk.com/': '钉钉',
          'https://open.weixin.qq.com/': '微信Oauth',
        };
        let type = '';
        if (sessionStorage.getItem('wxAuth') || this.loginType === '微信') {
          type = '微信';
        } else if (this.loginType === '手机号') {
          type = '手机号';
        } else if (document.referrer) {
          type = TYPE_MAP[document.referrer];
        } else {
          type = '其他';
        }
        // 触发登录/注册的页面
        const SOURCE_MAP_ALL = {
          '/': '大首页',
          '/newcustomera': '新客首页',
          '/templatecenter': '模板中心页',
          '/price/personal': '个人特权页',
          '/price/company': '企业特权页',
          '/designtools/solution': '专题页',
        };
        const SOURCE_MAP = {
          '/templatecenter/': '模板中心分类页',
          '/sj': '搜索结果页',
          '/tupian/': '搜索结果页',
          '-ke': '搜索结果页',
        };
        // 触发登录/注册的位置
        const LOCATION_MAP = {
          0: '其他',
          1: '右上角注册按钮',
          2: '点击模板弹窗注册',
          3: '个人/企业权益页点击购买弹窗注册',
          4: '设计工具页左侧',
          5: '新客首页-页面按钮',
          6: '新客首页-挽留弹窗',
        };
        const path = this.$route.path;
        const key = Object.keys(SOURCE_MAP).filter((key) =>
          path.includes(key),
        )[0];
        const source = SOURCE_MAP_ALL[path] || SOURCE_MAP[key] || '其他';

        const registerParams = {
          registration_way: type,
          registered_source: source,
          registered_location: LOCATION_MAP[this.logregShowLocation],
        };
        const loginParams = {
          login_type: type,
          login_page: source,
          login_location: LOCATION_MAP[this.logregShowLocation],
        };
        try {
          window.gio('track', `login_successful`, loginParams);
          tracker.loginReport({
            event: 'login_successful',
            datetime: Date.now(),
            params: loginParams,
          });
          if (this.userInfo.isNewUser) {
            window.gio('track', `registered_successfully`, registerParams);
            tracker.loginReport({
              event: 'registered_successfully',
              datetime: Date.now(),
              params: registerParams,
            });
          }
        } catch (error) {
          console.log(error);
        }
        // logreg页面(非跳转到三方平台)登录/注册成功后跳转到指定路径
        callBack && callBack();
      },
      // 初始化企业微信相关
      initWxWork() {
        // 获取企业微信用户充值状态
        this.getRechargeStatus();
        const ONE_DAY = 24 * 3600 * 1000;
        // 判断企业微信团队过期弹窗今天是否已显示
        const expirePoplastShowDate =
          localStorage.getItem(`expirePopTodayIsShow_${this.teamId}`) || 0;
        this.expirePopTodayIsShow =
          Date.now() - expirePoplastShowDate < ONE_DAY;
        // 判断企业微信团队购买限制弹窗今天是否已显示
        const limitPoplastShowDate =
          localStorage.getItem(`limitPopTodayIsShow_${this.teamId}`) || 0;
        this.limitPopTodayIsShow = Date.now() - limitPoplastShowDate < ONE_DAY;
      },
      // 获取企业微信用户充值状态
      getRechargeStatus() {
        this.$http.post('/workwx/gerWorkWxUserRechargeStatus').then((res) => {
          // -1：未登录，0：充值失败，1：充值成功
          this.wxWorkIsBuy = res.body.code === 0;
        });
      },
      setscreenMarketingForm(status) {
        this.showScreenMarkeyForm = status;
      },
      openSvip(
        vipContactSalesFrom = {
          forward_page_name: '',
          forward_module_name: '',
        },
      ) {
        // 团队成员不可购买会员
        if (this.teamInfo.teamRoleEnum !== 'Administrator') {
          if (this.isWorkWeixinUser) {
            this.$message(
              '企业微信团队暂不支持升级，详情请联系客服，400-871-8211',
            );
          } else {
            this.$message('请联系团队创建者进行购买');
          }
          return;
        }
        this.vipContactSalesFrom = vipContactSalesFrom;
        this.proVipVisible = true;
      },
    },
  };
  function testUrl({ fullPath: path }) {
    let remove = () => {
      Array.from(document.getElementsByClassName('baidu.push.js')).forEach(
        (elem) => {
          elem.remove();
        },
      );
    };
    let push = () => {
      remove();
      var bp = document.createElement('script');
      bp.classList.add('baidu.push.js');
      var curProtocol = window.location.protocol.split(':')[0];
      if (curProtocol === 'https') {
        bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
      } else {
        bp.src = 'http://push.zhanzhang.baidu.com/push.js';
      }
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(bp, s);
    };
    if (path === '/') {
      push();
      return '/';
    } else {
      remove();
      let url = sessionStorage.getItem('designNeedToBeOpen');
      if (url === null) return;
      if (this.$route.query.handleLogin) return;
      window.location.href = url;
      sessionStorage.removeItem('designNeedToBeOpen');
    }
  }
  // 初始化客服信息
  function customerServiceInit() {
    window.udeskShowPanel = (params = {}) => {
      try {
        const time = new Date().getTime();
        const { userId, nickname, vipLevelName, webToken, source, groupId } =
          params;
        const signature = CryptoJS.SHA1(
          `nonce=${time}&timestamp=${time}&web_token=${webToken}&1e15f01a61353b917f3f40df371d44d1`,
        )
          .toString()
          .toUpperCase();
        ud('init');
        ud({
          group_id: groupId,
          customer: {
            nonce: time,
            signature: signature,
            timestamp: time,
            web_token: webToken,
            im_user_key: '1e15f01a61353b917f3f40df371d44d1',
            c_cf_用户ID: userId,
            c_cf_用户昵称: nickname,
            c_cf_用户会员: vipLevelName,
            c_cf_来源入口: source,
          },
        });
        ud('showPanel');
      } catch (e) {
        console.log(e, 'init udesk api is error');
      }
    };
  }
</script>

<style lang="less">
  @import './base/reset.css';
  //预加载
  .tcaptcha-transform {
    overflow: hidden;
  }
  #app {
    position: relative;
    min-width: 1280px;
    // background-color: #f1f1f2;
    margin: 0 auto;
    overflow: hidden;
  }
  //控制组件内定位。防止弹窗等级被覆盖问题
  ckt-cop-new-header::part(headWrap) {
    position: static;
  }
  .my-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 1000;
    border-radius: 4px;
  }

  .slide-enter-active,
  .slide-leave-active {
    transition: opacity 0.2s;
  }

  .slide-enter,
  .slide-leave-active {
    opacity: 0;
  }

  .slide1-enter-active,
  .slide1-leave-active {
    transition: opacity 0.6s;
  }

  .slide1-enter,
  .slide1-leave-active {
    opacity: 0;
  }

  #main-content {
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: padding-top 0.4s;
    position: relative;
    padding-top: 56px;
    cursor: default;
    // scroll-behavior: smooth;
    &.index {
      padding-top: 0;
    }
  }
  .lt-modal-wrap--simple {
    .lt-modal-content {
      border-radius: 8px;
    }
  }
  .lt-modal-wrap--simple .lt-modal-close {
    right: -40px !important;
  }
  .lt-modal-wrap--simple .lt-icon-pic-close:before {
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    background: url(../img/login_close.svg) no-repeat;
  }
  .searchBarStyle {
    position: fixed;
    top: 7px;
    right: 372px;
    z-index: 1000;
  }
  .searchBarLeftStyle{
    right: 412px;
  }
  .searchBarNoLoginStyle{
    right: 372px;
  }
  .searchFlashipStyle {
    right: 302px;
  }
  .searchBarAdStyle{
    top: 42px;
    right: 412px;
  }
  @media screen and (max-width: 1449px){
    .searchBarStyle{
      right: 288px;
    }
    .searchBarLeftStyle{
      right: 288px;
    }
  }
  .customerServiceBtn {
    z-index: 98;
    width: 90px;
    height: 30px;
    position: fixed;
    right: 14px;
    bottom: 42px;
    font-size: 13px;
    line-height: 32px;
    background-color: #0773fc;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    padding-left: 31px;
    font-family: sans-serif, 'Microsoft YaHei', Arial;
    cursor: pointer;
    .customerServiceIcon {
      width: 25px;
      height: 27px;
      position: absolute;
      left: 5px;
      top: 5px;
      background-image: url(./kefu_logo.svg);
      cursor: pointer;
      background-repeat: no-repeat;
    }
  }

  .big-notice {
    display: block;
    width: 720px;
    height: 398px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .template-message {
    position: fixed;
    z-index: 100;
    top: 120px;
    right: 82px;
    width: 320px;
    background-color: #fff;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 4px 10px 0 rgba(12, 21, 35, 0.1);
    img {
      position: absolute;
      top: 14px;
      right: 14px;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
    header {
      height: 70px;
      line-height: 70px;
      padding: 0 20px;
      overflow: hidden;
      background-image: url('./base/template_message_header_bg.png');
      font-weight: bold;
    }
    main {
      padding: 0 20px 30px;
    }
    &__subtile {
      margin-top: 16px;
    }
    &__content {
      margin-top: 16px;
    }
    &__button {
      margin-top: 30px;
      text-align: center;
      a {
        display: inline-block;
        background: #eeb86e;
        font-weight: bold;
        font-size: 14px;
        color: #483213;
        line-height: 32px;
        padding: 0 42px;
        border-radius: 20px;
        border: none;
        outline: none;
        &:hover {
          background: #f9c57e;
          color: #483213;
        }
      }
    }
  }

  .modal-wrap.select-job-modal .modal1-center {
    width: 760px;
    height: 580px;
  }
  // 隐藏 QQ浏览器收藏按钮
  #qb_collection_img_mask {
    display: none !important;
  }

  // 企点客服最小化时隐藏“会话中”横条
  #_QIDIAN_WEB_IM_IFRAME_2852168584[data-isopen='0'] {
    display: none !important;
  }

  .transparent-bg {
    .lt-modal-content {
      background-color: transparent;
      box-shadow: none;
    }
  }
</style>
